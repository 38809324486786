.App {
  text-align: center;
  transition: all 1s ease-in-out;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 0
}

.App-link {
  color: #10546b;
  cursor: pointer;
}

.Project-gallery {
  background-color: #282c34;
  min-height: 100vh;
  margin-top: 0;
  padding-top: 10vh;
  margin-bottom: 0;
  padding-bottom: 2rem;
}

.section-header {
  padding-top: 10vh;
}

.Project-gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  margin: 0;
}

.Gallery-element {
  background-color: white;
  border-radius: 5px;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
}

.Gallery-element-content {
  position: relative;
  min-height: 70%;
  margin-top: auto;
}

.Gallery-element-content img {
  width: 100%;

}

.Gallery-element-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
}

.Gallery-element-footer a {
  background-color: #10546b;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 15px;
  color: white;
  width: 100%;
}

.Gallery-element-footer a:hover {
  background-color: #282c34;
  color: #10546b;
}

.Top-nav {
  background-color: #282c34;
  max-height: 10vh;
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  width: 100%;
  z-index: 1;
}

.caption {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(2px + 2vmin);
}



.App-logo-small {
  max-height: 8vmin;
  pointer-events: none;
}

.Top-nav-menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: calc(1px + 2vmin);
}

.Top-nav-menu li {
  padding: 0 1rem;
}

.Contact-section {
  background-color:#10546b;
  padding-top: 2rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Contact-form-section {
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 600px;
}

.Contact-form-section form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Contact-form-section form label {
  width: 100%;
  display: inline;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;

}

.Contact-form-section form input {
  width: 100%;
  height: 2rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
}

.Contact-form-section form textarea {
  width: 100%;
  height: 5rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
}


.Contact-form-section form button {
  width: 50%;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  background-color:#282c34;
  border: none;
  color:white;
}

.Right-social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.Right-social-media i {
  font-size: calc(10px + 10vmin);
  color:#282c34;
  padding: 1.5rem;

}

@media screen and (max-width: 1300px) {
  .Project-gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 960px) {
  .Project-gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .Project-gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .Right-social-media {
    flex-direction: column;
  }
  .Top-nav {
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .Top-nav-menu ul {
    font-size: calc(12px + 2vmin);
  }
}